/*!
 * version: 1.0.2
 * docs: https://github.com/yeild/jigsaw
 * 
 */
! function (n) {
    var e = {};

    function t(r) {
        if (e[r]) return e[r].exports;
        var i = e[r] = {
            i: r,
            l: !1,
            exports: {}
        };
        return n[r].call(i.exports, i, i.exports, t), i.l = !0, i.exports
    }
    t.m = n, t.c = e, t.d = function (n, e, r) {
        t.o(n, e) || Object.defineProperty(n, e, {
            enumerable: !0,
            get: r
        })
    }, t.r = function (n) {
        "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(n, Symbol.toStringTag, {
            value: "Module"
        }), Object.defineProperty(n, "__esModule", {
            value: !0
        })
    }, t.t = function (n, e) {
        if (1 & e && (n = t(n)), 8 & e) return n;
        if (4 & e && "object" == typeof n && n && n.__esModule) return n;
        var r = Object.create(null);
        if (t.r(r), Object.defineProperty(r, "default", {
            enumerable: !0,
            value: n
        }), 2 & e && "string" != typeof n)
            for (var i in n) t.d(r, i, function (e) {
                return n[e]
            }.bind(null, i));
        return r
    }, t.n = function (n) {
        var e = n && n.__esModule ? function () {
            return n.default
        } : function () {
            return n
        };
        return t.d(e, "a", e), e
    }, t.o = function (n, e) {
        return Object.prototype.hasOwnProperty.call(n, e)
    }, t.p = "", t(t.s = 0)
}([function (n, e, t) {
    "use strict";
    var r = Object.assign || function (n) {
        for (var e = 1; e < arguments.length; e++) {
            var t = arguments[e];
            for (var r in t) Object.prototype.hasOwnProperty.call(t, r) && (n[r] = t[r])
        }
        return n
    },
        i = function () {
            function n(n, e) {
                for (var t = 0; t < e.length; t++) {
                    var r = e[t];
                    r.enumerable = r.enumerable || !1, r.configurable = !0, "value" in r && (r.writable = !0), Object.defineProperty(n, r.key, r)
                }
            }
            return function (e, t, r) {
                return t && n(e.prototype, t), r && n(e, r), e
            }
        }();
    t(1);
    var o = 290,
        s = 155,
        a = 42,
        c = 9,
        l = Math.PI,
        u = a + 2 * c + 3;

    function d(n, e) {
        return Math.round(Math.random() * (e - n) + n)
    }

    function f(n, e) {
        var t = document.createElement(n);
        return t.className = e, t
    }

    function p(n, e) {
        n.classList.add(e)
    }

    function h() {
        // return require('../assets/logo.png');
        return "https://picsum.photos/" + o + "/" + s + "/?image=" + d(0, 1084)
    }

    function v(n, e, t, r) {
        n.beginPath(), n.moveTo(e, t), n.arc(e + a / 2, t - c + 2, c, .72 * l, 2.26 * l), n.lineTo(e + a, t), n.arc(e + a + c - 2, t + a / 2, c, 1.21 * l, 2.78 * l), n.lineTo(e + a, t + a), n.lineTo(e, t + a), n.arc(e + c - 2, t + a / 2, c + .4, 2.76 * l, 1.24 * l, !0), n.lineTo(e, t), n.lineWidth = 2, n.fillStyle = "rgba(255, 255, 255, 0.7)", n.strokeStyle = "rgba(255, 255, 255, 0.7)", n.stroke(), n[r](), n.globalCompositeOperation = "destination-over"
    }

    function b(n, e) {
        return n + e
    }

    function g(n) {
        return n * n
    }
    var m = function () {
        function n(e) {
            var t = e.el,
                i = e.width,
                a = void 0 === i ? 290 : i,
                c = e.height,
                l = void 0 === c ? 155 : c,
                u = e.onSuccess,
                d = e.onFail,
                f = e.onRefresh;
            ! function (n, e) {
                if (!(n instanceof e)) throw new TypeError("Cannot call a class as a function")
            }(this, n), o = a, s = l, t.style.position = "relative", t.style.width = o + "px", r(t.style, {
                position: "relative",
                width: o + "px",
                margin: "0 auto"
            }), this.el = t, this.onSuccess = u, this.onFail = d, this.onRefresh = f
        }
        return i(n, [{
            key: "init",
            value: function () {
                this.initDOM(), this.initImg(), this.bindEvents()
            }
        }, {
            key: "initDOM",
            value: function () {
                var n = function (n, e) {
                    var t = document.createElement("canvas");
                    return t.width = n, t.height = e, t
                }(o, s),
                    e = n.cloneNode(!0),
                    t = f("div", "sliderContainer");
                t.style.width = o + "px";
                var v = f("div", "canvasContainer")
                var i = f("div", "refreshIcon"),
                    a = f("div", "sliderMask"),
                    c = f("div", "slider"),
                    l = f("span", "sliderIcon"),
                    u = f("span", "sliderText");
                e.className = "block", u.innerHTML = "向右滑动填充拼图";
                var d = this.el;
                d.appendChild(v), v.appendChild(n), v.appendChild(i), v.appendChild(e), c.appendChild(l), a.appendChild(c), t.appendChild(a), t.appendChild(u), d.appendChild(t), r(this, {
                    canvas: n,
                    block: e,
                    sliderContainer: t,
                    refreshIcon: i,
                    slider: c,
                    sliderMask: a,
                    sliderIcon: l,
                    text: u,
                    canvasCtx: n.getContext("2d"),
                    blockCtx: e.getContext("2d")
                })
            }
        }, {
            key: "initImg",
            value: function () {
                var n = this,
                    e = function (n) {
                        var e = new Image;
                        return e.crossOrigin = "Anonymous", e.onload = n, e.onerror = function () {
                            e.setSrc(h())
                        }, e.setSrc = function (n) {
                            if (window.navigator.userAgent.indexOf("Trident") > -1) {
                                var t = new XMLHttpRequest;
                                t.onloadend = function (n) {
                                    var t = new FileReader;
                                    t.readAsDataURL(n.target.response), t.onloadend = function (n) {
                                        e.src = n.target.result
                                    }
                                }, t.open("GET", n), t.responseType = "blob", t.send()
                            } else e.src = n
                        }, e.setSrc(h()), e
                    }(function () {
                        n.draw(), n.canvasCtx.drawImage(e, 0, 0, o, s), n.blockCtx.drawImage(e, 0, 0, o, s);
                        var t = n.y - 2 * c - 1,
                            r = n.blockCtx.getImageData(n.x - 3, t, u, u);
                        n.block.width = u, n.blockCtx.putImageData(r, 0, t)
                    });
                this.img = e
            }
        }, {
            key: "draw",
            value: function () {
                this.x = d(u + 10, o - (u + 10)), this.y = d(10 + 2 * c, s - (u + 10)), v(this.canvasCtx, this.x, this.y, "fill"), v(this.blockCtx, this.x, this.y, "clip")
            }
        }, {
            key: "clean",
            value: function () {
                this.canvasCtx.clearRect(0, 0, o, s), this.blockCtx.clearRect(0, 0, o, s), this.block.width = o
            }
        }, {
            key: "bindEvents",
            value: function () {
                var n = this;
                this.el.onselectstart = function () {
                    return !1
                }, this.refreshIcon.onclick = function () {
                    n.reset(), "function" == typeof n.onRefresh && n.onRefresh()
                };
                var e = void 0,
                    t = void 0,
                    r = [],
                    i = !1,
                    s = function (n) {
                        e = n.clientX || n.touches[0].clientX, t = n.clientY || n.touches[0].clientY, i = !0
                    },
                    a = function (s) {
                        if (!i) return !1;
                        var a = s.clientX || s.touches[0].clientX,
                            c = s.clientY || s.touches[0].clientY,
                            l = a - e,
                            u = c - t;
                        if (l < 0 || l + 38 >= o) return !1;
                        n.slider.style.left = l + "px";
                        var d = (o - 40 - 20) / (o - 40) * l;
                        n.block.style.left = d + "px", p(n.sliderContainer, "sliderContainer_active"), n.sliderMask.style.width = l + "px", r.push(u)
                    },
                    c = function (t) {
                        if (!i) return !1;
                        var o, s;
                        if (i = !1, (t.clientX || t.changedTouches[0].clientX) === e) return !1;
                        o = n.sliderContainer, s = "sliderContainer_active", o.classList.remove(s), n.trail = r;
                        var a = n.verify(),
                            c = a.spliced,
                            l = a.verified;
                        c ? l ? (p(n.sliderContainer, "sliderContainer_success"), "function" == typeof n.onSuccess && (n.onSuccess(), setTimeout(() => { n.reset() }, 1500))) : (p(n.sliderContainer, "sliderContainer_fail"), n.text.innerHTML = "请再试一次", n.reset()) : (p(n.sliderContainer, "sliderContainer_fail"), "function" == typeof n.onFail && n.onFail(), setTimeout(function () {
                            n.reset()
                        }, 1e3))
                    };
                this.slider.addEventListener("mousedown", s), this.slider.addEventListener("touchstart", s), this.block.addEventListener("mousedown", s), this.block.addEventListener("touchstart", s), document.addEventListener("mousemove", a), document.addEventListener("touchmove", a), document.addEventListener("mouseup", c), document.addEventListener("touchend", c)
            }
        }, {
            key: "verify",
            value: function () {
                var n = this.trail,
                    e = n.reduce(b) / n.length,
                    t = n.map(function (n) {
                        return n - e
                    }),
                    r = Math.sqrt(t.map(g).reduce(b) / n.length),
                    i = parseInt(this.block.style.left);
                return {
                    spliced: Math.abs(i - this.x) < 10,
                    verified: 0 !== r
                }
            }
        }, {
            key: "reset",
            value: function () {
                this.sliderContainer.className = "sliderContainer", this.slider.style.left = 0, this.block.style.left = 0, this.sliderMask.style.width = 0, this.clean(), this.img.setSrc(h())
            }
        }]), n
    }();
    window.jigsaw = {
        init: function (n) {
            return new m(n).init()
        }
    }
}, function (n, e, t) {
    var r = t(2);
    "string" == typeof r && (r = [
        [n.i, r, ""]
    ]);
    var i = {
        hmr: !0,
        transform: void 0,
        insertInto: void 0
    };
    t(4)(r, i);
    r.locals && (n.exports = r.locals)
}, function (n, e, t) {
    (n.exports = t(3)(!1)).push([n.i,
        `.block {
            position: absolute;
            left: 0;
            top: 0;
            cursor: pointer;
            cursor: grab;
        }
        .block:active {
            cursor: grabbing;
        }
        .sliderContainer {
            position: relative;
            text-align: center;
            width: 290px;
            height: 40px;
            line-height: 40px;
            margin-top: 15px;
            background: #f7f9fa;
            color: #45494c;
            border: 1px solid #e4e7eb;
        }
        .sliderContainer_active .slider {
            height: 38px;
            top: -1px;
            border: 1px solid #1991FA;
        }
        .sliderContainer_active .sliderMask {
            height: 38px;\r\n  border-width: 1px;
        }
        .sliderContainer_success .slider {
            height: 38px;\r\n  top: -1px;\r\n  border: 1px solid #52CCBA;\r\n  background-color: #52CCBA !important;
        }
        .sliderContainer_success .sliderMask {
            height: 38px;\r\n  border: 1px solid #52CCBA;
            background-color: #D2F4EF;
        }
        .sliderContainer_success .sliderIcon {
            background-position: 0 0 !important;
        }
        .sliderContainer_fail .slider {
            height: 38px;\r\n  top: -1px;\r\n  border: 1px solid #f57a7a;
            background-color: #f57a7a !important;
        }
        .sliderContainer_fail .sliderMask {
            height: 38px;
            border: 1px solid #f57a7a;
            background-color: #fce1e1;
        }
        .sliderContainer_fail .sliderIcon {
            top: 14px;\r\n  background-position: 0 -82px !important;
        }
        .sliderContainer_active .sliderText, .sliderContainer_success .sliderText, .sliderContainer_fail .sliderText {
            display: none;
        }
        .sliderMask {\r\n  position: absolute;\r\n  left: 0;\r\n  top: 0;\r\n  height: 40px;\r\n  border: 0 solid #1991FA;\r\n  background: #D1E9FE;
        }
        .slider {\r\n  position: absolute;\r\n  top: 0;\r\n  left: 0;\r\n  width: 40px;\r\n  height: 40px;\r\n  background: #fff;
            box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);\r\n  transition: background .2s linear;\r\n  cursor: pointer;\r\n  cursor: grab;
        }
        .slider:active {\r\n  cursor: grabbing;\r\n}
        .slider:hover {\r\n  background: #1991FA;\r\n}
        .slider:hover .sliderIcon {\r\n  background-position: 0 -13px;\r\n}
        .sliderIcon {\r\n  position: absolute;\r\n  top: 15px;\r\n  left: 13px;\r\n  width: 14px;\r\n  height: 12px;
            background: url(http://cstaticdun.126.net//2.6.3/images/icon_light.f13cff3.png) 0 -26px;\r\n  background-size: 34px 471px;
        }
        .canvasContainer{
            // position: absolute;
            bottom: 0;
        }
        .refreshIcon {
            position: absolute;\r\n  right: 0;\r\n  top: 0;\r\n  width: 34px;\r\n  height: 34px;\r\n  cursor: pointer;
            background: url(http://cstaticdun.126.net//2.6.3/images/icon_light.f13cff3.png) 0 -437px;\r\n  background-size: 34px 471px;
        }`, ""])
}, function (n, e) {
    n.exports = function (n) {
        var e = [];
        return e.toString = function () {
            return this.map(function (e) {
                var t = function (n, e) {
                    var t = n[1] || "",
                        r = n[3];
                    if (!r) return t;
                    if (e && "function" == typeof btoa) {
                        var i = (s = r, "/*# sourceMappingURL=data:application/json;charset=utf-8;base64," + btoa(unescape(encodeURIComponent(JSON.stringify(s)))) + " */"),
                            o = r.sources.map(function (n) {
                                return "/*# sourceURL=" + r.sourceRoot + n + " */"
                            });
                        return [t].concat(o).concat([i]).join("\n")
                    }
                    var s;
                    return [t].join("\n")
                }(e, n);
                return e[2] ? "@media " + e[2] + "{" + t + "}" : t
            }).join("")
        }, e.i = function (n, t) {
            "string" == typeof n && (n = [
                [null, n, ""]
            ]);
            for (var r = {}, i = 0; i < this.length; i++) {
                var o = this[i][0];
                "number" == typeof o && (r[o] = !0)
            }
            for (i = 0; i < n.length; i++) {
                var s = n[i];
                "number" == typeof s[0] && r[s[0]] || (t && !s[2] ? s[2] = t : t && (s[2] = "(" + s[2] + ") and (" + t + ")"), e.push(s))
            }
        }, e
    }
}, function (n, e, t) {
    var r, i, o = {},
        s = (r = function () {
            return window && document && document.all && !window.atob
        }, function () {
            return void 0 === i && (i = r.apply(this, arguments)), i
        }),
        a = function (n) {
            var e = {};
            return function (n) {
                if ("function" == typeof n) return n();
                if (void 0 === e[n]) {
                    var t = function (n) {
                        return document.querySelector(n)
                    }.call(this, n);
                    if (window.HTMLIFrameElement && t instanceof window.HTMLIFrameElement) try {
                        t = t.contentDocument.head
                    } catch (n) {
                        t = null
                    }
                    e[n] = t
                }
                return e[n]
            }
        }(),
        c = null,
        l = 0,
        u = [],
        d = t(5);

    function f(n, e) {
        for (var t = 0; t < n.length; t++) {
            var r = n[t],
                i = o[r.id];
            if (i) {
                i.refs++;
                for (var s = 0; s < i.parts.length; s++) i.parts[s](r.parts[s]);
                for (; s < r.parts.length; s++) i.parts.push(m(r.parts[s], e))
            } else {
                var a = [];
                for (s = 0; s < r.parts.length; s++) a.push(m(r.parts[s], e));
                o[r.id] = {
                    id: r.id,
                    refs: 1,
                    parts: a
                }
            }
        }
    }

    function p(n, e) {
        for (var t = [], r = {}, i = 0; i < n.length; i++) {
            var o = n[i],
                s = e.base ? o[0] + e.base : o[0],
                a = {
                    css: o[1],
                    media: o[2],
                    sourceMap: o[3]
                };
            r[s] ? r[s].parts.push(a) : t.push(r[s] = {
                id: s,
                parts: [a]
            })
        }
        return t
    }

    function h(n, e) {
        var t = a(n.insertInto);
        if (!t) throw new Error("Couldn't find a style target. This probably means that the value for the 'insertInto' parameter is invalid.");
        var r = u[u.length - 1];
        if ("top" === n.insertAt) r ? r.nextSibling ? t.insertBefore(e, r.nextSibling) : t.appendChild(e) : t.insertBefore(e, t.firstChild), u.push(e);
        else if ("bottom" === n.insertAt) t.appendChild(e);
        else {
            if ("object" != typeof n.insertAt || !n.insertAt.before) throw new Error("[Style Loader]\n\n Invalid value for parameter 'insertAt' ('options.insertAt') found.\n Must be 'top', 'bottom', or Object.\n (https://github.com/webpack-contrib/style-loader#insertat)\n");
            var i = a(n.insertInto + " " + n.insertAt.before);
            t.insertBefore(e, i)
        }
    }

    function v(n) {
        if (null === n.parentNode) return !1;
        n.parentNode.removeChild(n);
        var e = u.indexOf(n);
        e >= 0 && u.splice(e, 1)
    }

    function b(n) {
        var e = document.createElement("style");
        return n.attrs.type = "text/css", g(e, n.attrs), h(n, e), e
    }

    function g(n, e) {
        Object.keys(e).forEach(function (t) {
            n.setAttribute(t, e[t])
        })
    }

    function m(n, e) {
        var t, r, i, o;
        if (e.transform && n.css) {
            if (!(o = e.transform(n.css))) return function () { };
            n.css = o
        }
        if (e.singleton) {
            var s = l++;
            t = c || (c = b(e)), r = C.bind(null, t, s, !1), i = C.bind(null, t, s, !0)
        } else n.sourceMap && "function" == typeof URL && "function" == typeof URL.createObjectURL && "function" == typeof URL.revokeObjectURL && "function" == typeof Blob && "function" == typeof btoa ? (t = function (n) {
            var e = document.createElement("link");
            return n.attrs.type = "text/css", n.attrs.rel = "stylesheet", g(e, n.attrs), h(n, e), e
        }(e), r = function (n, e, t) {
            var r = t.css,
                i = t.sourceMap,
                o = void 0 === e.convertToAbsoluteUrls && i;
            (e.convertToAbsoluteUrls || o) && (r = d(r));
            i && (r += "\n/*# sourceMappingURL=data:application/json;base64," + btoa(unescape(encodeURIComponent(JSON.stringify(i)))) + " */");
            var s = new Blob([r], {
                type: "text/css"
            }),
                a = n.href;
            n.href = URL.createObjectURL(s), a && URL.revokeObjectURL(a)
        }.bind(null, t, e), i = function () {
            v(t), t.href && URL.revokeObjectURL(t.href)
        }) : (t = b(e), r = function (n, e) {
            var t = e.css,
                r = e.media;
            r && n.setAttribute("media", r);
            if (n.styleSheet) n.styleSheet.cssText = t;
            else {
                for (; n.firstChild;) n.removeChild(n.firstChild);
                n.appendChild(document.createTextNode(t))
            }
        }.bind(null, t), i = function () {
            v(t)
        });
        return r(n),
            function (e) {
                if (e) {
                    if (e.css === n.css && e.media === n.media && e.sourceMap === n.sourceMap) return;
                    r(n = e)
                } else i()
            }
    }
    n.exports = function (n, e) {
        if ("undefined" != typeof DEBUG && DEBUG && "object" != typeof document) throw new Error("The style-loader cannot be used in a non-browser environment");
        (e = e || {}).attrs = "object" == typeof e.attrs ? e.attrs : {}, e.singleton || "boolean" == typeof e.singleton || (e.singleton = s()), e.insertInto || (e.insertInto = "head"), e.insertAt || (e.insertAt = "bottom");
        var t = p(n, e);
        return f(t, e),
            function (n) {
                for (var r = [], i = 0; i < t.length; i++) {
                    var s = t[i];
                    (a = o[s.id]).refs-- , r.push(a)
                }
                n && f(p(n, e), e);
                for (i = 0; i < r.length; i++) {
                    var a;
                    if (0 === (a = r[i]).refs) {
                        for (var c = 0; c < a.parts.length; c++) a.parts[c]();
                        delete o[a.id]
                    }
                }
            }
    };
    var x, y = (x = [], function (n, e) {
        return x[n] = e, x.filter(Boolean).join("\n")
    });

    function C(n, e, t, r) {
        var i = t ? "" : r.css;
        if (n.styleSheet) n.styleSheet.cssText = y(e, i);
        else {
            var o = document.createTextNode(i),
                s = n.childNodes;
            s[e] && n.removeChild(s[e]), s.length ? n.insertBefore(o, s[e]) : n.appendChild(o)
        }
    }
}, function (n, e) {
    n.exports = function (n) {
        var e = "undefined" != typeof window && window.location;
        if (!e) throw new Error("fixUrls requires window.location");
        if (!n || "string" != typeof n) return n;
        var t = e.protocol + "//" + e.host,
            r = t + e.pathname.replace(/\/[^\/]*$/, "/");
        return n.replace(/url\s*\(((?:[^)(]|\((?:[^)(]+|\([^)(]*\))*\))*)\)/gi, function (n, e) {
            var i, o = e.trim().replace(/^"(.*)"$/, function (n, e) {
                return e
            }).replace(/^'(.*)'$/, function (n, e) {
                return e
            });
            return /^(#|data:|http:\/\/|https:\/\/|file:\/\/\/|\s*$)/i.test(o) ? n : (i = 0 === o.indexOf("//") ? o : 0 === o.indexOf("/") ? t + o : r + o.replace(/^\.\//, ""), "url(" + JSON.stringify(i) + ")")
        })
    }
}]);

export default jigsaw;